.has-text-weight-extrabold {
  font-weight: 800;
}

.has-text-weight-black {
  font-family: 900;
}

.is-size-08 {
  font-size: 3.8rem;
}

.is-size-05 {
  font-size: 4.8rem;
}
